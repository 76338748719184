<template>
  <div>
    <b-button
        v-ripple.400="'rgba(234, 84, 85, 0.15)'"
        variant="outline-success"
        class="mt-0 mt-md-2"
        :block="$store.getters['app/currentBreakPoint'] === 'xs'"
        @click="modalShow = !modalShow"
        id="toggle-btn"
    >
      <feather-icon
          icon="EditIcon"
          class="mr-25"
      />
      <span>Edit</span>
    </b-button>

    <b-modal
        v-model="modalShow"
        :title="menu.title + ' Menu edit'"
        ok-title="Save"
        ok-only
        centered
        no-close-on-backdrop
        modal-class="modal-primary"
        id="modal-primary"
        size="lg"
        @show="resetModal"
        @hidden="resetModal"
        @ok="handleOk"
    >
      <!--   menu elements repeater   -->
      <singleMenuRepeater :children="menu.children" :roles="menu.roles" />
    </b-modal>
  </div>
</template>

<script>
import { BButton, BModal, VBModal } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import SingleMenuRepeater from './singleMenuRepeater.vue'

export default {
  name: 'EditMenuModal',
  components: {
    SingleMenuRepeater,
    BButton,
    BModal,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    menu: {
      type: Object,
      required: true,
    },
  },
  computed: {
  },
  data: () => ({
    modalShow: false,
  }),
  methods: {
    // checkFormValidity() {
    //   const valid = this.$refs.form.checkValidity()
    //   this.nameState = valid
    //   return valid
    // },
    resetModal() {
      // console.log('reset modal fields')
      // this.name = ''
      // this.nameState = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      // this.handleSubmit()
      this.modalShow = false
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      // Push the name to submitted names
      this.submittedNames.push(this.name)
      // Hide the modal manually
      this.$nextTick(() => {
        this.modalShow = false
        // this.$refs['my-modal'].toggle('#toggle-btn')
      })
    },
  },
}
</script>
