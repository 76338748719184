<template>
  <div>
    <div>
      <b-form
          ref="form"
          :style="{height: trHeight}"
          class="repeater-form"
          @submit.prevent="repeateAgain"
      >

        <!-- Row Loop -->
        <b-row
            v-for="(menu, index) in menus"
            :id="menu.id"
            :key="menu.id"
            ref="row"
        >

          <!-- Item Name -->
          <b-col md="3">
            <b-form-group
                label="Item Name"
                label-for="item-name"
            >
              <b-form-input
                  id="item-name"
                  type="text"
                  placeholder="ex: Shortcuts menu"
                  v-model="menu.title"
              />
            </b-form-group>
          </b-col>

          <!-- roles -->
          <b-col md="4">
            <b-form-group
                label="Visible to"
                label-for="roles"
            >
              <v-select
                  :reduce="x => x"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="roles"
                  :options="rolesOptions"
                  :clearable="false"
                  placeholder="Select role"
                  multiple
                  closeOnSelect="false"
                  v-model="menu.roles"
              />
            </b-form-group>
          </b-col>

          <!-- Order -->
          <b-col md="1">
            <b-form-group
                label="Order"
                label-for="order"
            >
              <b-form-input
                  id="order"
                  type="number"
                  placeholder="1"
                  v-model="menu.order"
              />
            </b-form-group>
          </b-col>

          <!-- Edit -->
          <b-col
              lg="2"
              md="2"
              class="mb-50"
          >
            <!-- button and modal-->
            <EditMenuModal :menu="menu" />
          </b-col>

          <!-- Remove Button -->
          <b-col
              lg="2"
              md="3"
              class="mb-50"
          >
            <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="mt-0 mt-md-2"
                @click="removeItem(index)"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              <feather-icon
                  icon="XIcon"
                  class="mr-25"
              />
              <span>Delete</span>
            </b-button>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>

      </b-form>
    </div>
    <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="repeateAgain"
    >
      <feather-icon
          icon="PlusIcon"
          class="mr-25"
      />
      <span>Add New</span>
    </b-button>
  </div>
</template>

<script>
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BButton,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import VSelect from 'vue-select'
import EditMenuModal from './EditMenuModal.vue'

export default {
  components: {
    EditMenuModal,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    VSelect,
  },
  name: 'MenusRepeater',
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    menus: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      rolesOptions: [
          'admin',
          'teacher',
          'student',
      ],
      nextTodoId: 2,
    }
  },
  watch: {
    menus() {
      this.initTrHeight()
    },
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    repeateAgain() {
      this.menus.push({
        id: this.nextTodoId += this.nextTodoId,
        children: [],
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.menus.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: inherit;
  transition: 0.35s height;
}
</style>
