<template>
  <div>
    <div>
      <b-form
          ref="form"
          style="{height: auto}"
          class="repeater-form"
          @submit.prevent="repeateAgain"
      >

        <!-- Row Loop -->
        <b-row
            v-for="(child, index) in children"
            :id="child.id"
            :key="child.id"
            ref="row"
        >

          <!-- Link text -->
          <b-col md="4">
            <b-form-group
                label="Link text"
                label-for="link-text"
            >
              <b-form-input
                  id="link-text"
                  type="text"
                  placeholder="ex: google website"
                  v-model="child.title"
              />
            </b-form-group>
          </b-col>

          <!-- Url -->
          <b-col md="4">
            <b-form-group
                label="Url"
                label-for="url"
            >
              <b-form-input
                  id="url"
                  type="text"
                  placeholder="ex: https://www.google.no"
                  v-model="child.href"
              />
            </b-form-group>
          </b-col>

          <!-- Order -->
          <b-col md="1">
            <b-form-group
                label="Order"
                label-for="order"
            >
              <b-form-input
                  id="order"
                  type="number"
                  placeholder="1"
                  v-model="child.order"
              />
            </b-form-group>
          </b-col>

          <!-- Remove Button -->
          <b-col
              lg="2"
              md="3"
              class="mb-50"
          >
            <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="mt-0 mt-md-2"
                @click="removeItem(index)"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              <feather-icon
                  icon="XIcon"
                  class="mr-25"
              />
              <span>Remove</span>
            </b-button>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>

      </b-form>
    </div>
    <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="repeateAgain"
    >
      <feather-icon
          icon="PlusIcon"
          class="mr-25"
      />
      <span>Add New</span>
    </b-button>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BButton,
  // BFormCheckbox,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'singleMenuRepeater',
  components: {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    // BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  props: {
    children: {
      type: Object,
      required: true,
    },
    roles: {
      type: Object,
      required: true,
    },
  },
  mixins: [heightTransition],
  data() {
    return {
      nextTodoId: 2,
    }
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    repeateAgain() {
      this.children.push({
        id: this.nextTodoId += this.nextTodoId,
        // roles: this.roles ?? [],
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.children.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
