<template>
  <div>
    <b-button
      id="toggle-btn"
      v-ripple.400="'rgba(234, 84, 85, 0.15)'"
      :block="$store.getters['app/currentBreakPoint'] === 'xs'"
      :disabled="!widget.valid"
      class="mt-0 mt-md-2"
      variant="outline-success"
      @click.prevent="validateAndShow"
    >
      <feather-icon
        class="mr-25"
        icon="EditIcon"
      />
      <span>Edit</span>
    </b-button>

    <b-modal
      id="modal-primary"
      v-model="modalShow"
      :ok-disabled="okButtonDisabled"
      :title="widget.title + ' Widget edit'"
      centered
      modal-class="modal-primary"
      no-close-on-backdrop
      ok-only
      ok-title="Save"
      size="lg"
      @hidden="resetModal"
      @ok="handleOk"
      @show="resetModal"
    >
      <!--   the modal contents   -->

      <b-form>
        <b-form-group
          label="Choose widget type"
          label-for="vue-select"
        >
          <v-select
            id="vue-select"
            v-model="widget.type"
            :clearable="false"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="typesOptions"
            :reduce="x => x.id"
          />
        </b-form-group>
        <!--        editor text-->
        <quill-editor
          v-if="widget.type !== 3 && widget.type !== 4"
          v-model="widget.content.text"
          :options="snowOption"
        />
        <!--        link-->
        <b-form-group
          :label="linkLabel"
          label-for="link"
        >
          <b-form-input
            id="link"
            v-model="widget.content.link"
            :placeholder="linkPlaceholder"
          />
        </b-form-group>
        <!--        background-->
        <b-form-group
          v-if="widget.type !== 3"
          :label="bgLabel"
          label-for="link"
        >
          <b-input-group class="mb-2 input-group-merge">
            <b-input-group-prepend
              v-if="widget.type !== 4"
              is-text
            >
              <b-form-checkbox
                v-model="widget.content.bg.is_image"
                switch
                @change="switchBg"
              >
                <span class="sr-only">Switch for following text input</span>
              </b-form-checkbox>
            </b-input-group-prepend>
            <b-input-group-prepend
              v-if="widget.content.bg.is_image"
              is-text
            >
              <feather-icon
                icon="ImageIcon"
                @click="simulateClick('image_to_upload')"
              />
            </b-input-group-prepend>
            <b-form-input
              id="image_to_upload"
              v-model="widget.content.bg.value"
              :type="bgInputType"
              aria-label="Text input with switch"
            />
          </b-input-group>
        </b-form-group>
        <b-form-file
          ref="refInputElimg"
          v-model="imageFile"
          :fsrc="fsrc"
          :hidden="true"
          accept=".jpg, .png, .gif"
          plain
          @change="getUploadContext"
        />
      </b-form>

    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupPrepend,
  BFormCheckbox, BFormFile,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'

export default {
  name: 'EditWidgetModal',
  components: {
    BFormFile,
    BButton,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BInputGroup,
    BInputGroupPrepend,
    BFormCheckbox,
    vSelect,
    quillEditor,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    widget: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    modalShow: false,
    typesOptions: [
      // {
      //   id: 1,
      //   label: 'text',
      // },
      {
        id: 2,
        label: 'Text',
      },
      {
        id: 3,
        label: 'Youtube video',
      },
      {
        id: 4,
        label: 'Image',
      },
      // {
      //   id: 5,
      //   label: 'slider',
      // },
    ],
  }),
  computed: {
    okButtonDisabled() {
      let response = false
      if (this.widget.type === 2) {
        response = this.widget.content.text !== 'undefined' && this.widget.content.text !== null && this.widget.content.text.length === 0
      }
      if (this.widget.type === 3) {
        response = this.widget.content.link !== null && this.widget.content.link.length === 0
      }
      if (this.widget.type === 4) {
        response = this.widget.content.bg.value !== null && this.widget.content.bg.value.length === 0
      }
      return response
    },
    bgInputType() {
      return `${(this.widget.content.bg.is_image === true ? 'text' : 'color')}`
    },
    bgLabel() {
      return `Background ${(this.widget.content.bg.is_image === true ? 'image' : 'color')}`
    },
    linkLabel() {
      return `${(this.widget.type === 3 ? 'ID of youtube video (string at the end of youtube link "v=")' : 'Widget link')}`
    },
    linkPlaceholder() {
      return `${(this.widget.type === 3 ? ' Enter ID ex: q45HmRIM08' : 'Enter link ex: https://google.no')}`
    },
  },
  methods: {
    getUploadContext() {
      // eslint-disable-next-line
      let formData = new FormData()
      const file = this.$refs.refInputElimg.$refs.input.files[0]
      formData.append('image', file)
      formData.append('field', this.clickSrc)
      this.$http.post('/school-settings', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Accept: 'application/json',
        },
      }).then(response => {
        console.log(response.data)
        this.widget.content.bg.value = response.data
      })
    },
    simulateClick(srcElement) {
      // trigger click and save the source prop of the field
      this.clickSrc = srcElement
      this.$refs.refInputElimg.$el.click()
    },
    validateAndShow() {
      this.$emit('validationRepeaterForm')
      setTimeout(() => {
        if (this.widget.valid) {
          this.modalShow = true
        }
      }, 50)
    },
    switchBg() {
      this.widget.content.bg.value = null
    },
    // checkFormValidity() {
    //   const valid = this.$refs.form.checkValidity()
    //   this.nameState = valid
    //   return valid
    // },
    resetModal() {
      // console.log('reset modal fields')
      // this.name = ''
      // this.nameState = null
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      // this.handleSubmit()
      this.modalShow = false
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return
      }
      // Push the name to submitted names
      this.submittedNames.push(this.name)
      // Hide the modal manually
      this.$nextTick(() => {
        this.modalShow = false
        // this.$refs['my-modal'].toggle('#toggle-btn')
      })
    },
  },
}
</script>
<style>
.quill-editor .ql-editor {
  min-height: 150px;
}

.ql-container.ql-snow {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.quill-editor {
  margin-bottom: 10px;
}

.ql-toolbar.ql-snow {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

</style>
