<template>
  <div>
    <div>
      <validation-observer ref="widgetsRules">
        <b-form
          ref="form"
          :style="{height: trHeight}"
          class="repeater-form"
          @submit.prevent="repeateAgain"
      >

        <!-- Row Loop -->
        <b-row
            v-for="(widget, index) in widgets"
            :id="index"
            :key="index"
            ref="row"
        >

          <!-- Item Name -->
          <b-col md="3">
            <b-form-group
                label="Item Name"
                label-for="item-name"
            >
              <validation-provider
                  #default="{ errors }"
                  name="Item Name"
                  :vid="'itemName'+index"
                  rules="required"
              >
                <b-form-input
                    id="item-name"
                    :ref="index+'-item-name'"
                    type="text"
                    placeholder="ex: Shortcuts widget"
                    v-model="widget.title"
                    :state="errors.length > 0 ? false:null"
                    @change="validationRepeaterForm"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>

          <!-- roles -->
          <b-col md="3">
            <b-form-group
                label="Visible to"
                label-for="roles"
            >
              <v-select
                  :reduce="x => x"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="roles"
                  :options="rolesOptions"
                  :clearable="false"
                  placeholder="Select role"
                  multiple
                  closeOnSelect="false"
                  v-model="widget.roles"
              />
            </b-form-group>
          </b-col>

          <!-- size -->
          <b-col md="1">
            <b-form-group
                label="size"
                label-for="sizes"
            >
              <v-select
                  :reduce="x => x.id"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="sizesOptions"
                  :clearable="false"
                  placeholder="Select sizes"
                  closeOnSelect="false"
                  v-model="widget.size"
              />
            </b-form-group>
          </b-col>

          <!-- Order -->
          <b-col md="1">
            <b-form-group
                label="Order"
                label-for="order"
            >
              <b-form-input
                  id="order"
                  type="number"
                  placeholder="1"
                  v-model="widget.order"
              />
            </b-form-group>
          </b-col>

          <!-- Edit -->
          <b-col
              lg="2"
              md="2"
              class="mb-50"
          >
            <!-- button and modal-->
            <EditWidgetModal :widget="widget" @validationRepeaterForm="validationRepeaterForm"/>
          </b-col>

          <!-- Remove Button -->
          <b-col
              lg="2"
              md="3"
              class="mb-50"
          >
            <b-button
                v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                variant="outline-danger"
                class="mt-0 mt-md-2"
                @click="removeItem(index)"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            >
              <feather-icon
                  icon="XIcon"
                  class="mr-25"
              />
              <span>Delete</span>
            </b-button>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-row>

      </b-form>
      </validation-observer>
    </div>
    <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        @click="repeateAgain"
    >
      <feather-icon
          icon="PlusIcon"
          class="mr-25"
      />
      <span>Add New</span>
    </b-button>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BForm, BFormGroup, BFormInput, BRow, BCol, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import VSelect from 'vue-select'
import EditWidgetModal from './EditWidgetModal.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    EditWidgetModal,
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    VSelect,
  },
  name: 'WidgetsRepeater',
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    widgets: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      rolesOptions: [
        'admin',
        'teacher',
        'student',
      ],
      sizesOptions: [
        { id: 12, name: '1/1' },
        { id: 6, name: '1/2' },
        { id: 3, name: '1/4' },
      ],
      nextTodoId: 2,
      required,
      email,
    }
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    validationRepeaterForm() {
      this.$refs.widgetsRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          console.log(success)
        }
        Object.entries(this.$refs.widgetsRules.errors).forEach(el => {
          const key = el[0].replace('itemName', '')
          if (el[1].length > 0) {
            this.widgets.[key].valid = false
          } else {
            this.widgets.[key].valid = true
          }
        })
      })
      this.initTrHeight()
    },
    repeateAgain() {
      this.widgets.push({
        id: this.nextTodoId += this.nextTodoId,
        content: {
          id: 1,
          text: '',
          link: null,
          bg: { is_image: true, value: null },
          order: 1,
        },
        type: 2,
        valid: true,
        size: 1,
        order: 1,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.widgets.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: inherit;
  transition: 0.35s height;
}
</style>
