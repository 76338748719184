<template>
  <component :is="schoolData === undefined ? 'div' : 'b-card'">

    <!-- Alert: No item found -->
    <b-container>
      <b-row>
        <b-col
            cols="12"
            md="6"
        >
            <h4>Menus & Widgets management</h4>
            <small>Managing the widgets and menus for all user's dashboards </small>
        </b-col>
<!--        <b-col-->
<!--            cols="12"-->
<!--            md="6"-->
<!--            class="text-right"-->
<!--        >-->
<!--          <b-button-->
<!--              variant="primary"-->
<!--              class="mb-1 mb-sm-0 mr-0 mr-sm-1"-->
<!--              :block="$store.getters['app/currentBreakPoint'] === 'xs'"-->
<!--          >Add widget</b-button>-->
<!--          <b-button-->
<!--              variant="primary"-->
<!--              class="mb-1 mb-sm-0 mr-0 mr-sm-1"-->
<!--              :block="$store.getters['app/currentBreakPoint'] === 'xs'"-->
<!--          >Add Menu</b-button>-->
<!--        </b-col>-->
      </b-row>
    </b-container>
    <hr/>
<!--    content here-->
      <b-tabs v-model="tabIndex">

        <b-tab :title="'Menus (' + this.menus.length + ')'">
          <MenusRepeater :menus="this.menus" />
        </b-tab>

        <b-tab :title="'Widgets (' + this.widgets.length + ')'">
          <WidgetsRepeater :key="tabIndex" :widgets="this.widgets" />
        </b-tab>

      </b-tabs>

    <b-row class="mt-2">
      <b-col class="text-right">
        <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            :block="$store.getters['app/currentBreakPoint'] === 'xs'"
            @click="onSave"
        >
          {{ $t('Save Changes') }}
        </b-button>
      </b-col>
    </b-row>

  </component>
</template>

<script>
import {
  BCard, BAlert, BLink, BButton, BRow, BCol, BTabs, BCardText, BTab,
} from 'bootstrap-vue'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useToast } from 'vue-toastification/composition'
import BCardCode from '@core/components/b-card-code'
// import { codeDefault } from '@/views/components/tab/code'
import schoolDashesStoreModule from '../schoolDashesStoreModule'
import MenusRepeater from './MenusRepeater.vue'
import WidgetsRepeater from './WidgetsRepeater.vue'
// import router from '@/router'

export default {
  components: {
    WidgetsRepeater,
    MenusRepeater,
    BRow,
    BCol,
    BCard,
    BAlert,
    BLink,
    BButton,
    BCardCode,
    BTabs,
    BCardText,
    BTab,
  },
  name: 'SchoolDashesSettingsEdit',
  data() {
    return {
      tabIndex: 0,
    }
  },
  setup() {
    const SCHOOL_DASHES_STORE_MODULE_NAME = 'app-school-dashes'

    // Register module
    if (!store.hasModule(SCHOOL_DASHES_STORE_MODULE_NAME)) store.registerModule(SCHOOL_DASHES_STORE_MODULE_NAME, schoolDashesStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SCHOOL_DASHES_STORE_MODULE_NAME)) store.unregisterModule(SCHOOL_DASHES_STORE_MODULE_NAME)
    })
    const schoolData = ref(null)
    const toast = useToast()

    const menus = ref([
      {
        id: 1,
        title: 'loading ..',
        order: 1,
        roles: [],
        children: [
          {
            id: 1,
            title: '',
            href: '',
            roles: null, // supposed to be here, but it existes in whole menu element
            order: 1,
          },
        ],
      },
    ])

    const widgets = ref([
      {
        id: 1,
        title: 'widget 1 title',
        order: 1,
        roles: ['student', 'admin'],
        size: 3,
        type: 2,
        valid: true,
        content: {
          id: 1,
          text: '',
          link: 'google',
          bg: { is_image: true, value: 'image' },
        },
      },
    ])

    const onSave = () => {
      // const toast = useToast()
      const payload = {
        menus: menus.value,
        widgets: widgets.value,
      }
      store.dispatch('app-school-dashes/update', payload)
          .then(() => {
            // console.log('success')
            toast({
              component: ToastificationContent,
              props: {
                title: 'School informations had been updated',
                icon: 'CheckIcon',
                variant: 'success',
              },
            })
            // router.back()
            // emit('refetch-data')
            // emit('update:is-add-new-user-sidebar-active', false)
          }).catch(error => {
            const message = typeof error.response.data.message !== 'undefined' ? error.response.data.message : 'Something went wrong!'
            toast({
              component: ToastificationContent,
              props: {
                title: message,
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
      })
    }

    onMounted(() => {
      store.dispatch('app-school-dashes/fetch', { id: 1 })
          .then(response => {
            const schoolSettings = { ...schoolDashesStoreModule.state.data }
            const newSchoolSettings = {}
            // console.log(schoolSettings)

            Object.keys(schoolSettings).forEach(k => {
              // console.log(k)
              if (typeof response.data.[k] !== 'undefined') {
                newSchoolSettings[k] = response.data.[k]
                // console.log(newSchoolSettings[k])
              } else {
                newSchoolSettings[k] = { value: null }
              }
            })
            menus.value = newSchoolSettings.menus
            widgets.value = newSchoolSettings.widgets
          })
          .catch(error => {
            if (error.response.status === 404) {
              schoolData.value = undefined
            }
          })
    })

    return {
      schoolData,
      onSave,
      menus,
      widgets,
    }
  },
}
</script>

<style>

</style>
